<template>
   <component v-bind:is="currentComponent"></component>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData ,formatDocument,formatCep} from "@/utils/helpers";
import ContentMenu from '../components/ContentMenu.vue';
import ProgressLoading from '../../../modules/auth/components/ProgressLoading.vue';
import Leitura from './components/Leitura.vue'
import Alteracao from './components/Alteracao.vue'
import AlteracaoSenha from './components/AlterarSenha.vue'
export default {
    name: "Cadastro",

    components: {
      ContentMenu,
      ProgressLoading,
      "cadastroLeitura": Leitura,
      "cadastroAtualizacao": Alteracao,
      "alterarSenha": AlteracaoSenha
    },
    data () {
        return {
            loadUserInfo: false,
            userInfo: null
        }
    },
    async created() {
        if(this.$route.params.password)
            this.ActionSetCurrentComponentRegister('alterarSenha');

        if(this.$route.params.updateInfo)
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');

    },
    methods: {
        formatValue,
        formatDocument,
        formatData,
        formatCep,
        ...mapActions('cadastro', ['ActionSetCurrentComponentRegister']),
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
    },
    computed:{ 
        ...mapState("auth", ["user"]),
        ...mapState('cadastro', ['currentComponent']),
    }
};
</script>