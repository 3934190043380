<template>
     <div class="card-menu" style="">
        <div class="row">
            <div class="col-12 col-md-12" style="" v-for="link in links" :key="link.text">
                <router-link  :to="link.route" class="nav-item"> <a class="btn btn-link mx-auto" > <i :class=link.icon></i> {{link.text}}</a></router-link> 
            </div>
        </div>
    </div>
</template>

<script>
import { mapState,mapActions} from "vuex";

export default {
    name: "ContentMenu",
    data () {
        return {
            links: []
        }
    },
    async created() {
        if(this.user != null){
            //await this.ActionGetUser();
            this.user.Menus.forEach(menuId => {
                switch(parseInt(menuId)){
                    case 2:
                        this.links.push({ icon: 'fa fa-folder-open-o fa-fw mr-2', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
                        break;
                    case 1:
                        this.links.push({ icon: 'fa fa-file-text-o fa-fw mr-2', text: 'Boletos Ativos', route: '/dashboard/meus-boletos' });
                        break;
                    case 3:
                        this.links.push({ icon: 'fa fa-handshake-o fa-fw mr-2', text: 'Acordos', route: '/dashboard/meus-acordos'});
                        break;
                    case 4:
                        this.links.push({ icon: 'fa fa-fw mr-2 fa-user-o', text: 'Cadastro', route: '/dashboard/cadastro'});
                        break;
                }
            });
        }
    },
    methods: {
        ...mapActions('auth', ['ActionGetUser'])
    },
    computed:{ 
        ...mapState('auth', ['user']),
        isLoggedIn(){ 
            return this.$store.getters['auth/isLoggedIn']
        } 
    }
}
</script>