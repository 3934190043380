<template>
  <div id="inside" style="">
    <div class="container">
      <div class="row">
        <div class="d-flex align-items-start justify-content-start flex-column px-2 pb-4 col-lg-12">
          <div class="btn-group btn-group-sm ">
            <a href="/" class="btn btn-link d-flex flex-column justify-content-center"><i class="fa fa-home fa-fw fa-lg"></i></a>
             <!-- <router-link  v-for="link in links" :key="link.text" :to="{
                  name:link.route,
                }"><a class="btn btn-link">{{link.text}}</a></router-link> -->
          </div>
        </div>
        <div class="col-lg-12">
          <h1> Cadastro</h1>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
         <div class="col-lg-3">
        </div>

         <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-8">
          <div class="card">
            <div class="card-header" style=""><i class="fa fa-fw mr-2 fa-user text-info"></i>Meu cadastro</div>
            <div class="card-body pb-5" style="">
              <div class="row">
                <div class="col-md-12 col-12">
                </div>
              </div>
              <form v-on:submit="saveUserInfo" v-on:submit.prevent>
                <div class="row">
                    <div class="col-12 col-md-1">
                    </div>
                    <div class="col-12 col-md-10" style="">
                    <h4 class="">Meus dados</h4>
                    <hr>
                    <p class="m-0 text-info">Nome:</p>
                    <h6 class="">{{user.Fullname}}</h6>
                    <p class="m-0 text-info">CPF:</p>
                    <h6>{{ formatDocument(user.DocNumber) }}</h6>
                    <p class="m-0 text-info">Data de nascimento:</p>
                    <h6> {{ formatData(user.BirthDate, "YYYY-MM-DD", "L") }} </h6>
                    
                        <div class="row">
                        <div class="col-md-7" style="">
                          <div class="form-group">
                            <input 
                              v-model="form.endereco"
                              maxlength="100"
                              :disabled="loading"
                              required
                              @input="$v.form.endereco.$touch()"
                              @blur="$v.form.endereco.$touch()"
                              type="text" class="form-control my-3" placeholder="Endereço">
                          </div>
                        </div>
                        <div class="col-md-2" style="">
                          <div class="form-group">
                            <input 
                            maxlength="8"
                              v-model="form.number"
                              :disabled="loading"
                              v-mask="'##########'"
                              type="text" class="form-control my-3" placeholder="Nº">
                          </div>
                        </div>
                        <div class="col-md-3" style="">
                          <div class="form-group">
                              <input 
                              maxlength="30"
                                v-model="form.complement"
                                :disabled="loading"
                                type="text" class="form-control my-3" placeholder="Complemento">
                          </div>
                        </div>
                        
                      </div>
                      <div class="row">
                        
                        <div class="col-md-3" style="">
                          <div class="form-group">
                            <input 
                              v-model="form.cep"
                              :disabled="loading"
                              v-mask="'#####-###'"
                              required
                              type="text" class="form-control my-3" placeholder="CEP">
                          </div>
                        </div>

                        <div class="col-md-3" style="">
                          <div class="form-group">
                            <input 
                              v-model="form.region"
                              :disabled="loading"
                              maxlength="20"
                              required
                              type="text" class="form-control my-3" placeholder="Bairro">
                          </div>
                        </div>

                        <div class="col-md-4" style="">
                          <div class="form-group">
                              <input 
                                v-model="form.city"
                                :disabled="loading"
                                required
                                maxlength="20"
                                type="text" class="form-control my-3" placeholder="Cidade">
                          </div>
                        </div>
                        <div class="col-md-2" style="">
                          <div class="form-group">
                            <input 
                              v-model="form.state"
                              :disabled="loading"
                              required
                              maxlength="2"
                              type="text" class="form-control my-3" placeholder="UF">
                          </div>
                        </div>
                      </div>
                      <hr>

                      <div class="row">
                        <div class="col-md-12" style="">
                          <div class="form-group">
                              <input 
                                v-model="form.email"
                                :disabled="loading"
                                required
                                maxlength="100"
                                type="text" class="form-control my-3" placeholder="E-mail">
                          </div>
                        </div>

                        <div class="col-md-6" style="">
                          <div class="form-group">
                              <input 
                                v-model="form.mobileNumber"
                                :disabled="loading"
                                required
                                v-mask="'(##)# ####-####'"
                                type="text" class="form-control my-3" placeholder="Celular">
                          </div>
                        </div>

                        <!-- <div class="col-md-6" style="">
                          <div class="form-group">
                              <input 
                                v-model="form.phone"
                                :disabled="loading"
                                required
                                v-mask="['(##)####-#### ','(##)# ####-####']"
                                type="text" class="form-control my-3" placeholder="Fixo">
                          </div>
                        </div> -->
                      </div>
                    <hr>
                    <div v-if="docNumberErrors.length" style="text-align:left;font-size:11px;">
                        <b v-for="error in docNumberErrors" v-bind:key="error" v-bind:class="classMessage" class="form-text">{{ error }}</b>
                    </div>
                    <div class="row" v-if="loading">
                          <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                              <ProgressLoading v-bind:loading="loading"/>
                          </div>
                      </div>

                    <button v-if="!loading" @click="voltar" type="buton" style="margin-top:10px;" class="btn mr-1 mb-1 btn-outline-secondary-grey">Voltar</button>
                    <button v-if="!loading" type="submit" style="margin-top:10px;" class="btn mr-1 mb-1 btn-outline-primary">Confirmar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-lg-1">
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { mapActions,mapState } from 'vuex';
import { mask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from '../../../../modules/auth/components/ProgressLoading.vue';
import { formatCurrencieValueBRL as formatValue, formatData,formatDocument,formatRemovePhoneMask,formatCep } from "@/utils/helpers";

export default {
    name: "Alterar",
    directives: {mask},
    mixins: [validationMixin],
    validations: {
        form: {
            endereco: {required, minLength: minLength(4)}
        }
    },
    components: {
      ProgressLoading
    },
    data: () => ({
       
        form : {
            endereco: null,
            number: null,
            cep:null,
            complement:null,
            region:null,
            state:null,
            city:null,
            email:null,
            phone:null,
            mobileNumber:null,
            birthDate: null
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        errors: false,
        loading: false
    }),
    async created() {
      // this.user.Menus.forEach(menuId => {
      //       switch(parseInt(menuId)){
      //           case 2:
      //               this.links.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
      //               break;
      //           case 1:
      //               this.links.push({ icon: '', text: 'Boletos Ativos', route: 'meusBoletos' });
      //               break;
      //           case 3:
      //               this.links.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
      //               break;
      //       }
      //   });

        this.form.endereco = this.user.Locale ? this.user.Locale : "";
        this.form.cep = this.user.CEP ? this.user.CEP : "";
        this.form.number = this.user.Number ? this.user.Number : "";
        this.form.complement = this.user.Complement ? this.user.Complement : "";
        this.form.state = this.user.State ? this.user.State : "";
        this.form.city = this.user.City ? this.user.City : "";
        this.form.email = this.user.Email ? this.user.Email : "";
        this.form.phone = this.user.PhoneNumber ? this.user.PhoneNumber : "";
        this.form.mobileNumber = this.user.MobileNumber ? this.user.MobileNumber : "";
        this.form.region = this.user.Region ? this.user.Region : "";
        this.form.birthDate = this.user.BirthDate ? this.user.BirthDate : "";
    },
    methods: {
        formatValue,
        formatDocument,
        formatRemovePhoneMask,
        formatData,
        formatCep,
        ...mapActions('cadastro',['ActionUpdateDebtor','ActionSetCurrentComponentRegister']),
        voltar() {
            this.ActionSetCurrentComponentRegister('cadastroLeitura');
        },
       
        async saveUserInfo(){
           this.loading = true;
           this.$v.$touch();
           this.docNumberErrors.pop();

           if (this.docNumberErrors.length > 0) {
              this.loading = false;
              return;
           }
           try {
                let response = await this.ActionUpdateDebtor({
                  Locale: this.form.endereco,
                  Number: this.form.number,
                  Complement: this.form.complement,
                  Region: this.form.region,
                  City:this.form.city,
                  State: this.form.state,
                  CEP: this.formatRemovePhoneMask(this.form.cep),
                  PhoneNumber: this.formatRemovePhoneMask(this.form.mobileNumber),
                  MobileNumber: this.formatRemovePhoneMask(this.form.mobileNumber),
                  Email: this.form.email
                });
                 
                this.loading = false;
                if(response.status != 200){
                  this.docNumberErrors.push('Ocorreu um erro ao tentar atualizar seus dados, tente novamente mais tarde.');
                  this.error.status = 'ERROR';
                  return;
                }
                 this.docNumberErrors.push('Seus dados foram alterados com sucesso!');
                 this.error.status = 'SUCCESS';
            } catch (error) {
                console.log(error);
               this.loading = false;
               this.docNumberErrors.push('Ocorreu um erro ao tentar atualizar seus dados, tente novamente mais tarde.');
               this.error.status = 'ERROR';
            }
         
        }
        
    },
    computed:{ 
        ...mapState('auth', ['user']),
        docNumberErrors () {
            const errors = [];
            if(!this.$v.form.endereco.$dirty) return errors;
            !this.$v.form.endereco.minLength && errors.push('digite um endereço válido');
            !this.$v.form.endereco.required && errors.push('endereço é obrigatório!');

            return errors;
        },
        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-info': this.error.status == "SUCCESS", 
                'text-muted': !this.error.status == "ERROR"
            }
        },
    }
};
</script>