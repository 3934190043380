<template>
  <div id="inside" style="">
    <div class="container">
      <div class="row">
        <div class="d-flex align-items-start justify-content-start flex-column px-2 pb-4 col-lg-12">
          <div class="btn-group btn-group-sm ">
            <a href="/" class="btn btn-link d-flex flex-column justify-content-center"><i class="fa fa-home fa-fw fa-lg"></i></a>
             <!-- <router-link  v-for="link in links" :key="link.text" :to="{
                  name:link.route,
                }"><a class="btn btn-link">{{link.text}}</a></router-link> -->
          </div>
        </div>
        <div class="col-lg-12">
          <h1> Cadastro</h1>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-3">
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-8">
          <div class="card">
            <div class="card-header" style=""><i class="fa fa-fw mr-2 fa-user text-info"></i>Meu cadastro</div>
            <div class="card-body pb-5" style="">
              <div class="row">
                <div class="col-md-12 col-12">
                </div>
              </div>
              <div class="row">
                 <div class="col-12 col-md-1">
                  </div>
                  <div class="col-12 col-md-10" style="">
                    <p class="m-0 text-info">Nome:</p>
                    <h6 class="">{{user.FullName}}</h6>
                    <p class="m-0 text-info">CPF:</p>
                    <h6>{{ formatDocument(user.DocNumber)}}</h6>
                    <p class="m-0 text-info">Data de nascimento:</p>
                    <h6>{{ formatData(user.BirthDate, "YYYY-MM-DD", "L") }}</h6>
                    
                    <div class="row">
                      <div class="col-md-7" style="">
                        <p class="m-0 text-info">Endereço:</p>
                        <p style="">{{user.Locale}}, {{user.Number}}, {{user.Complement}}</p>
                      </div>
                      <div class="col-md-5" style="">
                        <p class="m-0 text-info">CEP</p>
                        <p style="">{{user.CEP}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7" style="">
                        <p class="m-0 text-info">Cidade:</p>
                        <p style="">{{user.City}}</p>
                      </div>

                      <div class="col-md-5" style="">
                        <p class="m-0 text-info">UF</p>
                        <p style="">{{user.State}}</p>
                      </div>
                    </div>
                    <hr>
                    <h5 class="mb-3">Editar cadastro:</h5>
                      <a class="btn mr-1 mb-1 btn-outline-primary" id="button" @click="alterar" >Alterar dados</a>
                      <a class="btn mr-1 mb-1 btn-outline-primary" id="button" @click="alterarSenha"  style="">Alterar código de acesso</a>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-1">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData ,formatDocument,formatCep} from "@/utils/helpers";
export default {
    name: "Leitura",

    components: {
     
    },
    data () {
        return {
          links: [
              { icon: "", text: "Meu cadastro", route: "cadastro" },
              { icon: "", text: "Alterar senha", route: "cadastro" },
            ],
            // loadUserInfo: false,
            // userInfo: null
        }
    },
    async created() {
      // this.user.Menus.forEach(menuId => {
      //       switch(parseInt(menuId)){
      //           case 2:
      //               this.links.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
      //               break;
      //           case 1:
      //               this.links.push({ icon: '', text: 'Boletos Ativos', route: 'meusBoletos' });
      //               break;
      //           case 3:
      //               this.links.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
      //               break;
      //       }
      //   });
    },
    methods: {
        formatValue,
        formatDocument,
        formatData,
        formatCep,
        ...mapActions('cadastro',['ActionSetCurrentComponentRegister']),
        alterar() {
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');
        },
        alterarSenha() {
            this.ActionSetCurrentComponentRegister('alterarSenha');
        },
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
    }
};
</script>

<style scoped>

#button:hover {
  color: white
}


</style>